import {Link, useSearchParams,useNavigate} from 'react-router-dom';
import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { makeRequest } from "../axios.js";
import queryString from 'query-string';
// import gsap from 'gsap';
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from '../context/authContext.js';
import { HeaderContext } from '../context/headerContext.js';
import {FOLDER_MEDIA_PATH,thousandSeparator} from '../utils.js';
import 'react-toastify/dist/ReactToastify.css';

const Product = ({setLoginClicked}) => {
    // gsap.registerPlugin(ScrollTrigger);
    const [searchParams, setSearchParams] = useSearchParams();
    const {currentUser} = useContext(AuthContext);
    const {checkCartCounter} = useContext(HeaderContext);
    const [datas,setDatas] = useState(null);
    const [dataWishlist,setDataWishlist] = useState([]);
    // const [err,setErr] = useState(null);
    const [dataCategories,setDataCategories] = useState([]);
    const [niceSelectDropdown,setNiceSelectDropdown] = useState(false);
    // let location = useLocation();
    const [queryParam,setQueryParam] = useState({
        limit:parseInt(searchParams.get("limit")) || 8,
        page:searchParams.get("halaman"),
        sortBy:searchParams.get("urutkan") === 'produk_terlaris' ? 'totalSales' : searchParams.get("urutkan") === 'produk_terbaru' ? 'createdAt' : 'discountId',
        category:[searchParams.get("kategori")],
        subCategory: searchParams.get("subKategori").split(','),
        type:[],
        offer:[]
    });
    const [selectedCategories,setSelectedCategories] = useState([searchParams.get("kategori")]);
    const [selectedSubCategories,setSelectedSubCategories] = useState([searchParams.get("subKategori")]);
    const [categorySubCategories,setCategorySubCategories] = useState(searchParams.get("subKategori").split(','));
    const [selectedTypes,setSelectedTypes] = useState([]);
    const [selectedOffers,setSelectedOffers] = useState([]);
    const windowWidth = useRef(window.innerWidth);
    let isDesktop = windowWidth.current>980;
    const [hamburgerFilter,setHamburgerFilter] = useState(isDesktop ? true : false);

    const hamburgerFilterOnClick = (value) => {
        setHamburgerFilter(value);
    }
    const navigate = useNavigate();
    const handleClick = (value) => {
        navigate('/detail-produk/'+value);
    }
    const initCategories = async() => {
        try {
            const response = await makeRequest.get("categories/find-all");
            setDataCategories(response.data.data);
            var temps = [];
            response.data.data.map(data=>{
                let categoryName = data.name;
                let subCategoryTemp = [];
                if(data.sub_categories){
                    subCategoryTemp = data.sub_categories.map(subCategory=>subCategory.name);
                }
                temps.push({[categoryName]:subCategoryTemp})
            });
            setCategorySubCategories(temps);

            // if(selectedCategories){
            //     temps.map(data=>{
            //         Object.entries(data).map(([key,valueObj]) => {
            //             if(selectedCategories.includes(key)){
            //                 setSelectedSubCategories(valueObj);
            //             }
            //         });
            //     });
            // }
        } catch(err){
            alert('initCategories error!');
        }
    }
    const handleChangeboxSubCategories = () => {
        setSelectedSubCategories(queryParam.subCategory);
    }
    const initProductWishlist = async() => {
        if(currentUser){
            try {
                const result = await makeRequest.get('wishlists/find-by-user-id?userId='+currentUser.id);
                let temps = result.data.data.map(wishlistData=>wishlistData.productId);
                setDataWishlist(temps);
            } catch (error) {
                setDataWishlist([]);
            }
        } else {
            setDataWishlist([]);
        }
    }

    useLayoutEffect(()=>{
        initCategories();
        filterAndPagination();
        handleChangeboxSubCategories();
        initProductWishlist();
    },[queryParam.page,queryParam.sortBy,queryParam.category,queryParam.subCategory,queryParam.type,queryParam.offer]);
    // useLayoutEffect(()=>{
    //     gsap.to(".right-row .template-item", {
    //         autoAlpha:1,
    //         y:0,
    //         stagger: 0.3,
    //         overwrite:true,
    //         duration: .3
    //     });
    // });
    // const itemRef = useCallback((node) => {
    //     if (!node) return;
    //     const divs = node.querySelectorAll('.template-item')
    //     gsap.to(divs, {
    //         autoAlpha:1,
    //         y:0,
    //         stagger: 0.3,
    //         overwrite:true,
    //         duration: .3
    //     });
    // },[]);
    const filterAndPagination = async () => {
        try {
            const response = await makeRequest.get("products/pagination?"+queryString.stringify(queryParam));
            setDatas(response.data);
        } catch(err){
            alert('filterAndPagination error');
        }
    }
    const handleChange = (e,tipe) => {
        let isChecked = e.target.checked;
        let value = e.target.value;
        if(tipe==='tipe'){
            let types = [...selectedTypes];
            if(isChecked){
                types.push(value);
                setSelectedTypes(types);
                setQueryParam(prev=>{
                    return {
                        ...prev,
                        type:types
                    }
                });
            } else {
                let newTypes = types.filter(id=>id!==value);
                setSelectedTypes(newTypes);
                setQueryParam(prev=>{
                    return {
                        ...prev,
                        type:newTypes
                    }
                });
            }
        } else if(tipe==='penawaran'){
            let offers = [...selectedOffers];
            if(isChecked){
                offers.push(value);
                setSelectedOffers(offers);
                setQueryParam(prev=>{
                    return {
                        ...prev,
                        offer:offers
                    }
                });
            } else {
                let newOffers = offers.filter(id=>id!==value);
                setSelectedOffers(newOffers);
                setQueryParam(prev=>{
                    return {
                        ...prev,
                        offer:newOffers
                    }
                });
            }
        } else if(tipe==='kategori'){
            let categories = [...selectedCategories];
            let resultSubCategories = [];
            if(isChecked){
                categories.push(value);
                setSelectedCategories(categories);

                //new function and call at useeffect
                categorySubCategories.map(data=>{
                    Object.entries(data).map(([key,valueObj]) => {
                        if(key===value){
                            if(selectedSubCategories.length>0){
                                let remainingSubCategories = selectedSubCategories.filter(subCategoryVal => !valueObj.includes(subCategoryVal))
                                valueObj.map(val=>remainingSubCategories.push(val));
                                // console.log('remainingSubCategories: '+JSON.stringify(remainingSubCategories));
                                resultSubCategories = remainingSubCategories;
                                setSelectedSubCategories(remainingSubCategories);
                            } else {
                                resultSubCategories = valueObj;
                                setSelectedSubCategories(valueObj);
                            }
                        }
                    });
                });
                setQueryParam(prev => {
                    return {
                        ...prev,
                        category:categories,
                        subCategory:resultSubCategories
                    }
                });
            } else {
                let newCategories = categories.filter(name=>name !== value);
                setSelectedCategories(newCategories);
                categorySubCategories.map(data=>{
                    Object.entries(data).map(([key,valueObj]) => {
                        if(key===value){
                            if(selectedSubCategories.length>0){
                                let remainingSubCategories = selectedSubCategories.filter(subCategoryVal => !valueObj.includes(subCategoryVal))
                                setSelectedSubCategories(remainingSubCategories);
                                resultSubCategories = remainingSubCategories;
                            } else {
                                setSelectedSubCategories([]);
                                resultSubCategories = [];
                            }
                        }
                    });
                });
                setQueryParam(prev => {
                    return {
                        ...prev,
                        category:newCategories,
                        subCategory:resultSubCategories
                    }
                });
            }
        } else if(tipe==='subKategori'){
            let subCategories = [...selectedSubCategories];
            if(isChecked){
                subCategories.push(value);
                setSelectedSubCategories(subCategories);
                setQueryParam(prev => {
                    return {
                        ...prev,
                        subCategory:subCategories
                    }
                });
            } else {
                let newSubCategories = subCategories.filter(id=>id!==value);
                setSelectedSubCategories(newSubCategories);

                setQueryParam(prev => {
                    return {
                        ...prev,
                        subCategory:newSubCategories
                    }
                });
            }
        }
    };
    const handleDropdownChange = (e,value) => {
        setQueryParam((prev)=>({...prev, sortBy:value}));
        setNiceSelectDropdown(false);
    }
    const handleDropdownMobileChange = (e) => {
        setQueryParam((prev)=>({...prev, sortBy:e.target.value}));
    }
    const onClickPaging = (e,value) => {
        e.preventDefault();
        let resultPage = value+1;

        let query = {...queryParam};
        query.page = resultPage;

        setQueryParam(query);
    }
    const onClickPagingPrevOrNext = (e,type) => {
        if(type==='prev'){
            setQueryParam((prev)=>({...prev, page:queryParam.page-1}));
        } else if(type==='next'){
            setQueryParam((prev)=>({...prev, page:queryParam.page+1}));
        }
        filterAndPagination();
    }
    const addToCart = async(e,product) => {
        if(!currentUser){
            setLoginClicked(true);
        } else {
            const productColors = await makeRequest.get('product-colors/find-by-product-id?productId='+product.id);
            try {
                const dto = {
                    productId:product.id,
                    quantity:1,
                    price:parseInt(product.price),
                    productColorId:productColors.data.data[0].id
                }
                await makeRequest.post('carts/add-to-cart',dto);
                await checkCartCounter();
                callToastNotification('success','cart',null);
            } catch (error) {
                callToastNotification('error','cart',null);
            }
        }
    }

    const addOrRemoveToWishlist = async(e,productId) => {
        e.preventDefault();
        if(!currentUser){
            setLoginClicked(true);
        } else {
            try {
                const result = await makeRequest.get('wishlists/find-by-user-id-and-product-id?userId='+currentUser.id+'&productId='+productId)
                if(result.data.data){
                    await makeRequest.delete('wishlists?userId='+currentUser.id+'&productId='+productId);
                    callToastNotification('success','wishlist','remove');
                } else {
                    callToastNotification('error','wishlist',null);
                }
            } catch (error) {
                console.log('error',error)
                if(error.response.status===404){
                    var dto = {
                        userId:currentUser.id,
                        productId:productId
                    }
                    await makeRequest.post('wishlists',dto);
                    callToastNotification('success','wishlist','add');
                } else {
                    callToastNotification('error','wishlist',null);
                }
            }
            initProductWishlist();
        }
    }
    const toogleNiceSelectDropdown = () => {setNiceSelectDropdown(!niceSelectDropdown)}
    const callToastNotification = (status,type,process) => {
        if(type === 'cart'){
            if(status === 'success'){
                toast.success(<ButtonToCart/>, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else if(status === 'error'){
                toast.error('Servis sedang mengalami gangguan, dimohon untuk mencoba kembali lagi nanti!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else if(type==='wishlist'){
            if(status === 'success'){
                if(process==='add'){
                    toast.success(<ButtonSuccessAddWishlist/>, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    toast.success(<ButtonSuccessRemoveWishlist/>, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else if(status === 'error'){
                toast.error('Servis sedang mengalami gangguan, mohon untuk mencobanya kembali nanti!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    const ButtonToCart = () => {
        return (
            <a role='button' onClick={()=>goTo('keranjang')}>Barang berhasil ditambahkan ke keranjang. <strong>Klik disini untuk melihat keranjang.</strong></a>
        )
    }
    const ButtonSuccessAddWishlist = () => {
        return (
            <a role='button' onClick={()=>goTo('wishlist')}>Barang berhasil ditambahkan ke daftar wishlist. <strong>Klik disini untuk melihat wishlist.</strong></a>
        )
    }
    const ButtonSuccessRemoveWishlist = () => {
        return (
            <a role='button' onClick={()=>goTo('wishlist')}>Barang berhasil dihapus dari daftar wishlist. <strong>Klik disini untuk melihat wishlist.</strong></a>
        )
    }
    const goTo = (type) => {
        navigate('/profil',{state:{widget:type}});
    }
  return (
    <>
        <ToastContainer/>
        <div className="page-title-container">
            <img src={FOLDER_MEDIA_PATH+"Banner News.jpg"} alt="newsBannerImage" />
            <div className="content-container">
                <h1>Produk</h1>
                <ul className="breadcrumbs">
                    <li><Link to="/"><span>Beranda</span></Link></li>
                    <li>Produk</li>
                </ul>
            </div>
        </div>
        <div className="page-container">
            <div className="content-container">
                <div className="hamburger js-hamburger-filter" onClick={()=>hamburgerFilterOnClick(!hamburgerFilter)}>
                    <div>Filter</div>
                    <div className={hamburgerFilter ? "burger js-burger opened":"burger js-burger"}>
                        <div className="burgerpadding-wrapper">
                            <div className="burger-wrapper">
                                <div>
                                    <div></div>
                                </div>
                                <div>
                                    <div></div>
                                </div>
                                <div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="left-row" style={{display:hamburgerFilter ? 'block':'none'}}>
                        <ul className="filter-product">
                            <li>
                                <h5>Tipe</h5>
                                <ul>
                                    <li>
                                        <p>
                                            <input type="checkbox" id="cars" name="mobil" onChange={(e) => handleChange(e,'tipe')} checked={selectedTypes.includes('mobil')} value="mobil" />
                                            <span>Mobil</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <input type="checkbox" id="motorcycle" name="motor" onChange={(e) => handleChange(e,'tipe')} checked={selectedTypes.includes('motor')} value="motor" />
                                            <span>Motor</span>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <h5>Kategori</h5>
                                <ul>
                                    {dataCategories.length > 0 && dataCategories.map(category => {
                                        return (
                                            <li className="has-sub" key={category.id}>
                                                <p>
                                                    <input type="checkbox" id={category.name} value={category.name} onChange={(e) => handleChange(e,'kategori')} className="input-sub" checked={selectedCategories.includes(category.name)} />
                                                    <span>{category.name}</span>
                                                </p>
                                                <div className="sub-menu-filter" style={{display: selectedCategories.includes(category.name) ? 'block':'none'}}>
                                                    <ul>
                                                        {category.sub_categories.map(subCategory => {
                                                            return (
                                                                <li key={subCategory.id}>
                                                                    <p>
                                                                        <input type="checkbox" id={subCategory.name} onChange={(e) => handleChange(e,'subKategori')} checked={selectedSubCategories.includes(subCategory.name)} value={subCategory.name} className="input-sub-child" />
                                                                        <span>{subCategory.name}</span>
                                                                    </p>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li>
                            <li>
                                <h5>Penawaran</h5>
                                <ul>
                                    <li>
                                        <p>
                                            <input type="checkbox" id="discount" name="diskon" onChange={(e) => handleChange(e,'penawaran')} checked={selectedOffers.includes('diskon')} value="diskon" />
                                            <span>Diskon</span>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <input type="checkbox" id="productGuarantee" name="garansi" onChange={(e) => handleChange(e,'penawaran')} checked={selectedOffers.includes('garansi')} value="garansi" />
                                            <span>Produk Bergaransi</span>
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        {/* <div className="inline-space">
                            <a className="reset-filter" onClick={reset}>Reset filter</a>
                            <a className="apply-filter" onClick={apply}>Apply Filter</a>
                        </div> */}
                    </div>
                    {datas !== null &&
                        <div className="right-row">
                            <div className="shop-sort-cover">
                                <div className="sort-left">{datas.totalRows === 0 ? 'produk tidak ditemukan' : datas.totalRows+' produk ditemukan'} </div>
                                <div className="sort-right">
                                    <div className="sort-by">
                                        <div className="sort-name">Urutkan Berdasarkan:</div>
                                        {isDesktop ? (
                                            <div className={niceSelectDropdown ? "nice-select js-nice-select open" : "nice-select js-nice-select"} tabIndex="0">
                                            <span onClick={toogleNiceSelectDropdown} className="current">{queryParam.sortBy === 'totalSales' ? 'Produk Terlaris' : queryParam.sortBy === 'createdAt' ? 'Produk Terbaru' : 'Produk Promo'}</span>
                                            <ul className="list">
                                                <li data-value="Produk Terlaris" className={queryParam.sortBy === 'totalSales' ? "option disabled" : "option"} onClick={e=>handleDropdownChange(e,'totalSales')}>Produk Terlaris</li>
                                                <li data-value="Produk Terbaru" className={queryParam.sortBy === 'createdAt' ? "option disabled" : "option"} onClick={e=>handleDropdownChange(e,'createdAt')}>Produk Terbaru</li>
                                                <li data-value="Produk Promo" className={queryParam.sortBy === 'discountId' ? "option disabled" : "option"} onClick={e=>handleDropdownChange(e,'discountId')}>Produk Promo</li>
                                            </ul>
                                        </div>
                                        ) : (
                                            <select class={niceSelectDropdown ? "nice-select open":"nice-select"} onChange={handleDropdownMobileChange}>
                                            <option selected={queryParam.sortBy === 'totalSales' ? "selected" : ""} value='totalSales'>Produk Terlaris</option>
                                            <option selected={queryParam.sortBy === 'createdAt' ? "selected" : ""} value='createdAt'>Produk Terbaru</option>
                                            <option selected={queryParam.sortBy === 'discountId' ? "selected" : ""} value='discountId'>Produk Promo</option>
                                        </select>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="template-container" ref={itemRef}> */}
                            <div className="template-container">
                                {datas.result.map(data=>{
                                    return (
                                        <div className="template-item" key={data.id}>
                                            <div className="template-card">
                                                {data.guaranteeId && <img src={FOLDER_MEDIA_PATH+"garansi.svg"} className="garansi" alt='garansi'/>}
                                                {data.discountId && <img src={FOLDER_MEDIA_PATH+"discount.svg"} className="sale" alt="sale" />}
                                                <div className="template-card-header" onClick={()=>handleClick(data.path)}>
                                                    <img src={FOLDER_MEDIA_PATH+data.files.split(',')[0]}alt="productImage" />
                                                </div>
                                                <div className="template-card-product">
                                                    <div className="top-product-info">
                                                        <div className="top-product-name">
                                                            <div className="brand">{data.brandName}</div>
                                                            <div className="name">{data.name}</div>
                                                        </div>
                                                    </div>
                                                    <div className="bottom-product-info">
                                                        <div className="bottom-product-price">Rp. {thousandSeparator(data.price)}</div>
                                                        <div className="utility-product">
                                                            <a className="js-add-cart" title="Add to Cart" onClick={(e)=>addToCart(e,data)}>
                                                                <svg viewBox="0 0 260.293 260.293">
                                                                    <g>
                                                                        <path d="M258.727,57.459c-1.42-1.837-3.612-2.913-5.934-2.913H62.004l-8.333-32.055c-0.859-3.306-3.843-5.613-7.259-5.613H7.5
                                                                            c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h33.112l8.333,32.055c0,0.001,0,0.001,0.001,0.002l29.381,112.969
                                                                            c0.859,3.305,3.843,5.612,7.258,5.612h137.822c3.415,0,6.399-2.307,7.258-5.612l29.385-112.971
                                                                            C260.636,61.687,260.147,59.295,258.727,57.459z M117.877,167.517H91.385l-5.892-22.652h32.384V167.517z M117.877,129.864H81.592
                                                                            l-5.895-22.667h42.18V129.864z M117.877,92.197H71.795l-5.891-22.651h51.973V92.197z M176.119,167.517h-43.242v-22.652h43.242
                                                                            V167.517z M176.119,129.864h-43.242v-22.667h43.242V129.864z M176.119,92.197h-43.242V69.546h43.242V92.197z M217.609,167.517
                                                                            h-26.49v-22.652h32.382L217.609,167.517z M227.403,129.864h-36.284v-22.667h42.18L227.403,129.864z M237.201,92.197h-46.081V69.546
                                                                            h51.974L237.201,92.197z"/>
                                                                        <path d="M105.482,188.62c-15.106,0-27.396,12.29-27.396,27.395c0,15.108,12.29,27.4,27.396,27.4
                                                                            c15.105,0,27.395-12.292,27.395-27.4C132.877,200.91,120.588,188.62,105.482,188.62z M105.482,228.415
                                                                            c-6.835,0-12.396-5.563-12.396-12.4c0-6.835,5.561-12.395,12.396-12.395c6.834,0,12.395,5.561,12.395,12.395
                                                                            C117.877,222.853,112.317,228.415,105.482,228.415z"/>
                                                                        <path d="M203.512,188.62c-15.104,0-27.392,12.29-27.392,27.395c0,15.108,12.288,27.4,27.392,27.4
                                                                            c15.107,0,27.396-12.292,27.396-27.4C230.908,200.91,218.618,188.62,203.512,188.62z M203.512,228.415
                                                                            c-6.833,0-12.392-5.563-12.392-12.4c0-6.835,5.559-12.395,12.392-12.395c6.836,0,12.396,5.561,12.396,12.395
                                                                            C215.908,222.853,210.347,228.415,203.512,228.415z"/>
                                                                    </g>
                                                                </svg>
                                                            </a>
                                                            <a className="add-fav-product" title="Add to Favourite" onClick={(e)=>addOrRemoveToWishlist(e,data.id)}>
                                                                <svg viewBox="0 0 400 400" style={{fill:dataWishlist.includes(data.id) && 'rgb(136, 197, 64)'}}><g transform="matrix(1.3333333,0,0,-1.3333333,0,400)" id="g10"><g transform="scale(0.1)" id="g12"><path id="path14" style={{fillOpacity:1,fillRule:"nonzero",stroke:"none"}} d="m 903,2424.4 c 157.9,0 306.4,-61.5 418.1,-173.1 l 134.8,-134.9 c 20.7,-20.6 48.1,-32 77.1,-32 29,0 56.4,11.4 77,32 l 133.7,133.7 c 111.7,111.6 259.9,173.1 417.5,173.1 156.91,0 305,-61.3 416.8,-172.5 111.2,-111.3 172.5,-259.5 172.5,-417.5 0.6,-157.3 -60.69,-305.5 -172.5,-417.4 L 1531.5,373.5 487.402,1417.6 c -111.601,111.7 -173.105,259.9 -173.105,417.5 0,158.1 61.199,306.1 172.5,416.8 111.308,111.2 259.101,172.5 416.203,172.5 z m 1829.7,-19.6 c 0,0 0,0 -0.1,0 -152.4,152.4 -355.1,236.3 -570.9,236.3 -215.7,0 -418.7,-84.1 -571.5,-236.9 l -56.9,-57 -58.2,58.2 c -153.1,153.1 -356.3,237.5 -572.1,237.5 -215.305,0 -417.902,-83.9 -570.305,-236.3 -153,-153 -236.8942,-356 -236.2966,-571.5 0,-215 84.4026,-417.8 237.4966,-571 L 1454.7,143.301 c 20.5,-20.403 48.41,-32.199 76.8,-32.199 28.7,0 56.7,11.5 76.7,31.597 L 2731.5,1261.8 c 152.7,152.7 236.8,355.7 236.8,571.4 0.7,216 -83,419 -235.6,571.6"></path></g></g></svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="pagination-cover">
                                <ul className="pagination">
                                    {queryParam.page > 1 &&
                                        <li className="pagination-item item-prev">
                                            <a>
                                                <i className="fa fa-angle-left" aria-hidden="true" onClick={(e)=>onClickPagingPrevOrNext(e,'prev')}></i>
                                            </a>
                                        </li>
                                    }
                                    {Array.from({length: datas.totalPage}, (_,i)=>{
                                        return (
                                            <li onClick={(e)=>onClickPaging(e,i)} key={i} className={`pagination-item ${parseInt(queryParam.page) === i+1 ? "active" : ""}`}>
                                                <a>{i+1}</a>
                                            </li>
                                        )
                                    })}
                                    {queryParam.page < datas.totalPage &&
                                        <li className="pagination-item item-next">
                                            <a><i className="fa fa-angle-right" aria-hidden="true" onClick={(e)=>onClickPagingPrevOrNext(e,'next')}></i></a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </>
  )
}

export default Product