import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App.js';
import { AuthContextProvider } from './context/authContext';
import { HeaderContextProvider } from './context/headerContext.js';
import { LoadingProvider } from './context/LoadingContext.js';
import Loader from './components/Loader.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthContextProvider>
      <HeaderContextProvider>
        <LoadingProvider>
          <App />
          <Loader/>
        </LoadingProvider>
      </HeaderContextProvider>
    </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
