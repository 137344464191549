import { Link,useLocation, useNavigate } from 'react-router-dom';
import {FOLDER_MEDIA_PATH,convertDate} from '../utils.js';
import { useState } from 'react';
import { makeRequest } from '../axios.js';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../context/authContext.js';
import { useRef } from 'react';
import moment from 'moment';
import HTMLReactParser from 'html-react-parser';

const NewsDetail = () => {
    // const formatDate = "dd-MM-yyyy";
    // const navigate = useNavigate();
    // const {currentUser} = useContext(AuthContext);
    // const location = useLocation();
    // let newsId = location.pathname.split('/')[2];

    // const [emailLabelActive,setEmailLabelActive] = useState('');
    // const [emailFilled,setEmailFilled] = useState('');
    // const [usernameLabelActive,setUsernameLabelActive] = useState('');
    // const [usernameFilled,setUsernameFilled] = useState('');
    // const [commentLabelActive,setCommentLabelActive] = useState('');
    // const [commentFilled,setCommentFilled] = useState('');
    // const emailRef = useRef(null);
    // const usernameRef = useRef(null);
    // const commentRef = useRef(null);

    // const [data,setData] = useState(null);
    // const [input,setInput] = useState({username:'',email:'',comments:'',userId:currentUser ? currentUser.id : '',newsId:newsId});
    // const [repliedComments,setRepliedComments] = useState({repliedComments:'',userId:currentUser ? currentUser.id : '',commentId:0});

    // const [archives,setArchives] = useState([]);
    // const [categories,setCategories] = useState([]);

    // const replyComment = (commentId) => {
    //     let newsComments = [...data.news_comments];
    //     let index = newsComments.findIndex(comment => comment.id === commentId);

    //     for(let i=0;i<newsComments.length;i++){
    //         if(index===i){
    //             newsComments[i].newsCommentStatus = true;
    //         } else {
    //             newsComments[i].newsCommentStatus = false;
    //         }
    //     }

    //     setData({...data,news_comments:newsComments})
    // }
    // const addNewsComment = async(e) => {
    //     e.preventDefault();
    //     try {
    //         await makeRequest.post('comments',input);
    //         window.location.reload();
    //     } catch (error) {
    //         alert('failed');
    //     }
    // }
    // const addCommentsComment = async(commentId) => {
    //     try {
    //         await makeRequest.post('replied',repliedComments);
    //         window.location.reload();
    //     } catch (error) {
    //         alert('failed');
    //     }
    // }
    // const initData = async() => {
    //     try {
    //         const result = await makeRequest.get('news/find-by-id?id='+newsId);
    //         let resultData = result.data.data;
    //         for(let i=0;i<resultData.news_comments.length;i++){
    //             resultData.news_comments[i].newsCommentStatus = false;
    //         }
    //         setData(resultData);
    //     } catch (error) {
    //         setData(null);
    //     }
    // }
    // const handleFocus = (e,type) => {
    //     e.preventDefault();
    //     if(type==='email'){
    //         emailRef.current.focus();
    //         setEmailLabelActive('active');
    //     } else if(type==='comment') {
    //         commentRef.current.focus();
    //         setCommentLabelActive('active');
    //     } else {
    //         usernameRef.current.focus();
    //         setUsernameLabelActive('active');
    //     }
    // }
    // const handleBlur = (e,type) => {
    //     const {value} = e.target;
    //     if(type==='email'){
    //         emailRef.current.blur();
    //         if(value!==''){
    //             setEmailFilled('filled')
    //         } else {
    //             setEmailLabelActive('');
    //             setEmailFilled('');
    //         }
    //     } else if(type==='comment') {
    //         commentRef.current.blur();
    //         if(value!==''){
    //             setCommentFilled('filled')
    //         } else {
    //             setCommentLabelActive('');
    //             setCommentFilled('');
    //         }
    //     } else {
    //         usernameRef.current.blur();
    //         if(value!==''){
    //             setUsernameFilled('filled')
    //         } else {
    //             setUsernameLabelActive('');
    //             setUsernameFilled('');
    //         }
    //     }
    // }
    // const initArchives = async() => {
    //     try {
    //         const result = await makeRequest.get('news-archives/find-all');
    //         setArchives(result.data.data);
    //     } catch (error) {
    //         setArchives([]);
    //     }
    // }
    // const initCategories = async() => {
    //     try {
    //         const result = await makeRequest.get('news-categories/find-all');
    //         setCategories(result.data.data);
    //     } catch (error) {
    //         setCategories([]);
    //     }
    // }
    // useEffect(()=>{
    //     initData();
    //     initArchives();
    //     initCategories();
    // },[]);
  return (
    <>
        {/* <div className="page-title-container">
            <img src={FOLDER_MEDIA_PATH+"Banner News.jpg"} alt="newsBannerImage" />
            <div className="content-container">
                <h1>Berita</h1>
                <ul className="breadcrumbs">
                    <li><Link to="/">Beranda</Link></li>
                    <li><Link to="/berita">Berita</Link></li>
                    <li>Detail Berita</li>
                </ul>
            </div>
        </div>
        {data &&
            <div className="page-container">
                <div className="content-container">
                    <div className="section-news row">
                        <div className="left-section-news">
                            <div className="news-item">
                                <h4 className="news-title">
                                    <a role="button">{data.title}</a>
                                </h4>
                                <div className="news-header">
                                    <a role="button">
                                        <img src={FOLDER_MEDIA_PATH+data.pathImage} alt='news-image'/>
                                    </a>
                                    <div className="meta">
                                        <span className="post-date"><i className="fa fa-calendar" aria-hidden="true"></i>{convertDate(formatDate,data.createdAt)}</span>
                                        <span className="post-category">Category: <a role="button">Coaching</a><a role="button">Lorem</a></span>
                                    </div>
                                </div>
                                <div className="news-content">
                                    {HTMLReactParser(data.newsDetail)}
                                </div>
                                <div className="news-footer">
                                    <div className="meta">
                                        <span>
                                            <a role="button">{data.news_comments.length > 0 ? data.news_comments.length : 'Tidak ada'} Komentar</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {data.news_comments.length>0 &&
                                <>
                                    <h4 className="section-title">Komentar</h4>
                                    <ul className="review-list">
                                        {data.news_comments.map(comment=>{
                                            return (
                                                <li className="item" key={comment.id}>
                                                    <div className="review-item">
                                                        <div className="review-avatar">
                                                            <img src={comment.user.imageName !== '' ? FOLDER_MEDIA_PATH+comment.user.imageName : FOLDER_MEDIA_PATH+'ICON-USER.png'} alt="userImage" />
                                                        </div>
                                                        <div className="review-content">
                                                            <div className="name">{comment.user.name}</div>
                                                            <div className="date">{moment(comment.createdAt).fromNow()}</div>
                                                            <p>{comment.comments}</p>
                                                            {currentUser && <a role="button" className="review-btn" onClick={()=>replyComment(comment.id)}><i className="fa fa-reply" aria-hidden="true"></i> Balas</a>}
                                                            {comment.newsCommentStatus &&
                                                                <div className="reply-comment-content">
                                                                    <form>
                                                                        <input type='text' className='reply-comment-input' onChange={(e)=>setRepliedComments(prev=>({...prev,repliedComments:e.target.value,commentId:comment.id}))}/>
                                                                        <button type='button' className='reply-comment-button' onClick={addCommentsComment}>Kirim</button>
                                                                    </form>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {comment.comments_replieds.length > 0 &&
                                                        <ul className="child">
                                                            {comment.comments_replieds.map(replied=>{
                                                                return (
                                                                    <li className="item" key={replied.id}>
                                                                        <div className="review-item">
                                                                            <div className="review-avatar">
                                                                                <img src={replied.user.imageName !== '' ? FOLDER_MEDIA_PATH+replied.user.imageName : FOLDER_MEDIA_PATH+'ICON-USER.png'} alt="userImage" />
                                                                            </div>
                                                                            <div className="review-content">
                                                                                <div className="name">{replied.user.name}</div>
                                                                                <div className="date">{moment(replied.createdAt).fromNow()}</div>
                                                                                <p>{replied.repliedComments}</p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    }
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </>
                            }
                            {currentUser &&
                                <form>
                                    <h4 className="section-title">Tulis Komentar</h4>
                                    <div className="inline-space mb1">
                                        <div className="form-group w50 pr1">
                                            <div className="mb0">
                                                <label className={`${usernameLabelActive} ${usernameFilled}`}>Username</label>
                                                <input type="text" id="username" name="username" className="form-control login-input" onChange={(e)=>setInput(prev=>({...prev,username:e.target.value}))} required onBlur={(e) => handleBlur(e,'username')} onClick={(e) => handleFocus(e,'username')} ref={usernameRef}/>
                                            </div>
                                        </div>
                                        <div className="form-group w50 pl1">
                                            <div className="mb0">
                                                <label className={`${emailLabelActive} ${emailFilled}`}>Email</label>
                                                <input type="text"  id="email" name="email" className="form-control login-input" required onChange={(e)=>setInput(prev=>({...prev,email:e.target.value}))} onBlur={(e) => handleBlur(e,'email')} onClick={(e) => handleFocus(e,'email')} ref={emailRef}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb2">
                                        <div className="form-group">
                                            <div className="mb0" style={{height:"140px"}}>
                                                <textarea className="form-control login-input" style={{height:"140px"}} onChange={(e)=>setInput(prev=>({...prev,comments:e.target.value}))} onBlur={(e) => handleBlur(e,'comment')} onClick={(e) => handleFocus(e,'comment')} ref={commentRef}></textarea>
                                                <label className={`${commentLabelActive} ${commentFilled}`}>Komentar</label>
                                            </div>
                                        </div>
                                    </div>
                                    <a className="btnNine btnNine-default" role="button" onClick={addNewsComment}>Kirim Komentar</a>
                                </form>
                            }
                        </div>
                        <div className="right-section-news">
                            <ul className="widgets">
                                <li className="widget-list widget-archive">
                                    <h5 className="title">Arsip</h5>
                                    <ul>
                                        {archives?.map(archive=>(
                                            <li key={archive.id}><Link to={`/berita/arsip/${archive.path}`} role="button">{archive.name}</Link></li>
                                        ))}
                                    </ul>
                                </li>
                                <li className="widget-list widget-categories">
                                    <h5 className="title">Kategori</h5>
                                    <ul>
                                        {categories?.map(category=>(
                                            <li key={category.id}><Link to={`/berita/kategori/${category.name}`} role="button">{category.name}</Link></li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        } */}
        <h1>berhasil</h1>
    </>
  )
}

export default NewsDetail