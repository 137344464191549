import { Link,useLocation,useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { makeRequest } from "../axios.js";
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../context/authContext.js';
import { HeaderContext } from '../context/headerContext.js';
import {FOLDER_MEDIA_PATH,thousandSeparator} from '../utils.js';
import {GlassMagnifier} from "react-image-magnifiers";
// import { set } from 'date-fns';
import HTMLReactParser from 'html-react-parser';
import { useCallback } from 'react';
import { useLoading } from '../context/LoadingContext.js';

const ProductDetail = ({setLoginClicked}) => {
    const { showLoader, hideLoader } = useLoading();
    let location = useLocation();
    const navigate = useNavigate();
    const {currentUser} = useContext(AuthContext);
    const {checkCartCounter,checkNotificationCounter} = useContext(HeaderContext);
    const [initHeight,setInitHeight] = useState(0);
    const detaillRef = useCallback(node => {
        if (node !== null) {
            setInitHeight(node.getBoundingClientRect().height);
        }
    },[]);

    const [data,setData] = useState(null);
    const [tab,setTab] = useState('detail');
    const [counter,setCounter] = useState(1);
    const [total,setTotal] = useState(0);
    const [labelActive,setLabelActive] = useState('');
    const [filled,setFilled] = useState('');
    const [dynamicHeight,setDynamicHeight] = useState(initHeight);
    const [colorItem,setColorItem] = useState('');
    const [wishlistActive,setWishlistActive] = useState(false);

    let userWishlistsData=[];
    const [images,setImages] = useState([]);
    const [activeIndex,setActiveIndex] = useState(0);
    const [colors,setColors] = useState([]);
    const [stock,setStock] = useState(0);

    const detailRef = useRef(null);
    const specRef = useRef(null);
    const noteRef = useRef(null);
    const textareaRef = useRef(null);

    useEffect(()=>{
        initUserWishlists();
        initData();
        if(detailRef.current){
            setDynamicHeight(detailRef.current.clientHeight);
        }
    },[initHeight]);
    const initData = async() => {
        try {
            const result = await makeRequest.get('products/find-by-path?path='+location.pathname.split('/')[2]);
            if(result.status===200){
                let resultData = result.data.data;
                setData(resultData);
                setWishlistActive(userWishlistsData.includes(resultData.id));
                let imagesData = [];
                resultData.product_colors[0].product_color_images.map(image=>imagesData.push(image.file.name));
                setImages(imagesData);
                setColors(resultData.product_colors.map(color=>color.color.name));
                setColorItem(resultData.product_colors[0].color.name);
                setStock(resultData.product_colors[0].stock);
                setTotal(resultData.price);
            } else {
                setData(null);
                setImages([]);
                setWishlistActive(false);
                setColors([]);
                setColorItem('');
                setStock(0);
                setTotal(0);
            }
        } catch (error) {
            setData(null);
            setImages([]);
            setWishlistActive(false);
            setColors([]);
            setColorItem('');
            setStock(0);
            setTotal(0);
        }
    }

    const initUserWishlists = async() => {
        if(!currentUser){
            userWishlistsData = [];
        } else {
            try {
                const result = await makeRequest.get('wishlists/find-by-user-id?userId='+currentUser.id);
                let productIds = [];
                let dataWishlisht = result.data.data;
                for(let i=0;i<dataWishlisht.length;i++){
                    productIds.push(dataWishlisht[i].productId);
                }
                userWishlistsData = productIds;
            } catch (error) {
                userWishlistsData = [];
            }
        }
    }

    const changeTab = async(tabType) => {
        await setTab(tabType);
        if(tabType==='detail'){
            setDynamicHeight(detailRef.current.offsetHeight);
        } else if(tabType==='spec'){
            setDynamicHeight(specRef.current.offsetHeight);
        } else if(tabType==='note'){
            setDynamicHeight(noteRef.current.offsetHeight);
        }
    }
    const handleCounter = (e,type,price) => {
        e.preventDefault();
        let result = 0;
        if(type==='minus'){
            if(counter===1){
                result=1;
            } else {
                result = counter-1;
            }
        } else {
            result = counter+1;
        }
        setCounter(result);
        setTotal(parseInt(price*result));
    }
    const addOrRemoveToWishlist = async(e,productId,wishlistActiveValue) => {
        e.preventDefault();
        if(!currentUser){
            setLoginClicked(true);
        } else {
            var dto = {
                userId:currentUser.id,
                productId:productId
            }
            if(wishlistActiveValue){
                const result = await makeRequest.post('wishlists',dto);
                callToastNotification(result.status === 201 ? 'success' : 'error','wishlist','add');
            } else {
                const result = await makeRequest.delete('wishlists?userId='+dto.userId+'&productId='+dto.productId);
                callToastNotification(result.status === 200 ? 'success' : 'error','wishlist','remove');
            }
            setWishlistActive(wishlistActiveValue);
        }
    }
    const addToCart = async(e,dataProduct) => {
        e.preventDefault();
        if(!currentUser){
            setLoginClicked(true);
        } else {
            const color = await makeRequest.get('colors/find-by-name?name='+colorItem);
            const productColor = await makeRequest.get('product-colors/find-by-product-and-color?productId='+dataProduct.id+'&colorId='+color.data.data.id);
            try {
                const dto = {
                    productId:dataProduct.id,
                    quantity:counter,
                    price:parseInt(counter)*parseInt(dataProduct.price),
                    productColorId:productColor.data.data.id
                }
                await makeRequest.post('carts/add-to-cart',dto);
                callToastNotification('success','cart',null);
            } catch (error) {
                callToastNotification('error','cart',null);
            }
            await checkCartCounter();
        }
    }
    const addToBuy = async(e,data) => {
        e.preventDefault();
        await showLoader();
        if(!currentUser){
            setLoginClicked(true);
        } else {
            let alamatId = '';
            try {
                const addresses = await makeRequest.get('user-address/find-all');
                alamatId=addresses.data.data[0].id;
            } catch (error) {
                if(error.response.data.status===404){
                    alert('Mohon untuk mengisi alamat terlebih dahulu. Terima kasih.');
                    await hideLoader();
                    return;
                }
            }

            let colorObj = await data.product_colors.filter(productColor=>productColor.color.name===colorItem);
            let dto = {
                total:parseInt(counter)*parseInt(data.price),
                status:'UNPAID',
                products:[{
                    productColorId: colorObj[0].id,
                    productId: data.id,
                    quantity: counter,
                    price: parseInt(data.price)
                }],
                weight:Math.round(parseInt(data.weight)*parseInt(counter)/1000),
                addressId:alamatId
            }

            //save to order
            try {
                const response = await makeRequest.post('transactions/orders',dto);
                await checkNotificationCounter();
                navigate("/pembayaran/"+response.data.data.id);
            } catch (error) {
                console.log(error.response.data.message);
            }
            await hideLoader();
        }
    }
    const handleCLickFocus = () => {
        textareaRef.current.focus();
        setLabelActive('active');
    }
    const handleBlur = (e) => {
        textareaRef.current.blur();
        const {value} = e.target;
        if(value!==''){
            setFilled('filled')
        } else {
            setLabelActive('');
            setFilled('');
        }
    }
    //notification - start
    const callToastNotification = (status,type,process) => {
        if(type === 'cart'){
            if(status === 'success'){
                toast.success(<ButtonToCart/>, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else if(status === 'error'){
                toast.error('Servis sedang mengalami gangguan, dimohon untuk mencoba kembali lagi nanti!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else if(type==='wishlist'){
            if(status === 'success'){
                if(process==='add'){
                    toast.success(<ButtonSuccessAddWishlist/>, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    toast.success(<ButtonSuccessRemoveWishlist/>, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else if(status === 'error'){
                toast.error('Servis sedang mengalami gangguan, dimohon untuk mencoba kembali lagi nanti!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    const ButtonToCart = () => {
        return (
            <a role='button' onClick={()=>goTo('keranjang')}>Barang berhasil ditambahkan ke keranjang. <strong>Klik disini untuk melihat keranjang.</strong></a>
        )
    }
    const ButtonSuccessAddWishlist = () => {
        return (
            <a role='button' onClick={()=>goTo('wishlist')}>Barang berhasil ditambahkan ke daftar wishlist. <strong>Klik disini untuk melihat wishlist.</strong></a>
        )
    }
    const ButtonSuccessRemoveWishlist = () => {
        return (
            <a role='button' onClick={()=>goTo('wishlist')}>Barang berhasil dihapus dari daftar wishlist. <strong>Klik disini untuk melihat wishlist.</strong></a>
        )
    }
    const goTo = (type) => {
        navigate('/profil',{state:{widget:type}});
    }
    //notification - end
    const changeImage = (value) => {
        setActiveIndex(value);
    }
    const changeColor = (value) => {
        let dataObj = {...data};
        let color = dataObj.product_colors.filter(color=>color.color.name===value)[0];
        let images = color.product_color_images.map(image=>image.file.name);
        setImages(images);
        setColorItem(value);
        setStock(color.stock);
    }
  return (
    <>
        <ToastContainer/>
        <div className="page-title-container">
            <img src={FOLDER_MEDIA_PATH+"Banner News.jpg"} alt="newsBannerImage" />
            <div className="content-container">
                <h1>Produk</h1>
                <ul className="breadcrumbs">
                    <li><Link to="/">Beranda</Link></li>
                    <li><Link to="/produk">Produk</Link></li>
                    <li>Detail Produk</li>
                </ul>
            </div>
        </div>
        {data &&
            <div className="page-container" >
                <div className="content-container">
                    <div className="section-product row">
                        <div className="left-section-product">
                            <div className="bordered-box bordered-product">
                                {data.promos.length>0 && <img src={FOLDER_MEDIA_PATH+"discount.svg"} className="sale" alt="discount" />}
                                {data.product_guarantee && <img src={FOLDER_MEDIA_PATH+"garansi.svg"} className="garansi" alt="garansi" />}
                                <div id='el' className="magnif-wrapper">
                                    <GlassMagnifier
                                        imageSrc={FOLDER_MEDIA_PATH + images[activeIndex]}
                                        imageAlt="glass-magnifier"
                                        className='zoom-custom-main'
                                    />
                                    <div className="zoom-custom-thumb">
                                        {images.map((image,index)=>{
                                            return (
                                                <img key={index} onClick={()=>changeImage(index)} className='zoom-custom-thumb-detail' alt='img-small' src={FOLDER_MEDIA_PATH+image} />
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="left-product-brand">{data.brand.name}</div>
                                <div className="left-product-name">{data.name}</div>
                                <div className="left-product-price">Rp {thousandSeparator(data.price)},-</div>
                            </div>
                        </div>
                        <div className="right-section-product">
                            <div className="bordered-box">
                                <div className="semibold mb1">Pilih Warna :</div>
                                <div className="color-select-wrapper">
                                    {colors.map((color,idx)=>(<div key={idx} className={colorItem === color ? "color-item active":"color-item"} onClick={()=>changeColor(color)}>{color}</div>))}
                                </div>
                                <div className="tab-wrap">
                                    <ul className="product-tabs tab-nav">
                                        <li className={tab === 'detail' ? "active":""} onClick={()=>changeTab('detail')} data-tab="tab1">Detail</li>
                                        <li className={tab === 'spec' ? "active":""} onClick={()=>changeTab('spec')} data-tab="tab2">Spesifikasi</li>
                                        <li className={tab === 'note' ? "active":""} onClick={()=>changeTab('note')} data-tab="tab3">Info</li>
                                    </ul>
                                    <div className="tab-content" style={{height:dynamicHeight+'px'}}>
                                        <div ref={(el)=>{detailRef.current=el; detaillRef(el);}} className={tab === 'detail' ? "tab visible":"tab"} id="tab1">
                                            {HTMLReactParser(data.details)}
                                        </div>
                                        <div ref={(el)=>specRef.current=el} className={tab === 'spec' ? "tab visible":"tab"} id="tab2">
                                            {HTMLReactParser(data.specs)}
                                        </div>
                                        <div ref={(el)=>noteRef.current=el} className={tab === 'note' ? "tab visible":"tab"} id="tab3">
                                            {HTMLReactParser(data.infos)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bordered-box">
                                <div className="flex mb1" style={{alignItems:"center"}}>
                                    <div className="quantity-cart inline-space">
                                        <div className="counter js-counter" data-counter="minus" onClick={(e)=>handleCounter(e,'minus',data.price)}>
                                            <i className="fa fa-minus"></i>
                                        </div>
                                        <div className="counter-number">
                                            <input type="text" name="counter-number" id="counter-number" className="form-control val-counter" value={counter} readOnly={true}/>
                                        </div>
                                        <div className="counter js-counter" data-counter="plus" onClick={(e)=>handleCounter(e,'plus',data.price)}>
                                            <i className="fa fa-plus"></i>
                                        </div>
                                    </div>
                                    <div className="fz14">Total Stok : <span className="semibold">{stock}</span></div>
                                </div>
                                <div className="form-group mb2">
                                    <div className="mb0" style={{height:"140px"}}>
                                        <textarea ref={textareaRef} className="form-control login-input" onClick={handleCLickFocus} onBlur={handleBlur} style={{height:"140px"}}></textarea>
                                        <label className={`${labelActive} ${filled}`}>Tambah Catatan</label>
                                    </div>
                                </div>
                                <div className="inline-space mb2">
                                    <div className="fz14">Subtotal</div>
                                    <div className="fz16 semibold text-right">Rp {thousandSeparator(total)},-</div>
                                </div>
                                <div className="clearfix">
                                    <div className="pull-right">
                                        <a className={wishlistActive ? "btnNine btn-utils-product active":"btnNine btn-utils-product"} onClick={(e)=>addOrRemoveToWishlist(e,data.id,!wishlistActive)}>
                                            <svg viewBox="0 0 1080 1080"><path className="cls-1" d="M308.26,66.83c75.66.06,134.81,16.49,188.79,50.53a305.74,305.74,0,0,1,34.68,25.91c6.36,5.41,10.23,6,16.82.16,36.93-32.63,79.9-54.53,127.56-65.86,114.14-27.14,216.62-3.91,303.54,76.38,50.35,46.51,81,104.75,94.62,172.2,11.47,56.76,4.67,111.63-11,166.69C1046.65,551.1,1015.71,601,978.6,647.6,918,723.79,846.41,789,771,850.07a2406.24,2406.24,0,0,1-223.45,160.59c-5.61,3.57-9.47,3.45-15-.08-85.3-54.71-167.29-113.95-245.1-178.89-70.9-59.17-137.6-122.57-194.09-196C64.68,598.39,40.07,558.59,24,514c-32.41-90.1-34.37-179.51,8.56-266.89C72,166.82,134.27,111.67,219.42,82.9,252.14,71.86,285.76,67.09,308.26,66.83ZM540.36,271c-12-14.13-22.9-27.82-34.66-40.76-51.71-56.93-115.56-84.94-193-81.78-33,1.35-63.91,9.62-93.52,23.48-45.21,21.15-79.66,54.21-104.17,97.31C89.1,314.68,78.14,363.63,84.83,415.93,90,456,101.86,494,121.65,529.32c31.79,56.73,74.39,105,120.24,150.4,88.72,87.91,187.5,163.37,290.57,233.48,6.34,4.31,10.34,3.46,16.13-.48C656,839.59,758.91,760.9,850.41,668.15c37.5-38,72.8-77.93,100.29-124,33.59-56.25,52-116.53,45-182.84C990,308,968.48,261.62,931.45,223.18c-54.56-56.63-121.58-81-200-72.83C669.19,156.81,617,183.47,575,229.83,563.25,242.83,552.39,256.64,540.36,271Z"/></svg>
                                        </a>
                                    </div>
                                    <div className="pull-right">
                                        <a className="btnNine btn-utils-product" onClick={(e)=>addToCart(e,data)}>
                                            <svg className="wish-cart" viewBox="0 0 260.293 260.293">
                                                <g>
                                                    <path d="M258.727,57.459c-1.42-1.837-3.612-2.913-5.934-2.913H62.004l-8.333-32.055c-0.859-3.306-3.843-5.613-7.259-5.613H7.5
                                                        c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h33.112l8.333,32.055c0,0.001,0,0.001,0.001,0.002l29.381,112.969
                                                        c0.859,3.305,3.843,5.612,7.258,5.612h137.822c3.415,0,6.399-2.307,7.258-5.612l29.385-112.971
                                                        C260.636,61.687,260.147,59.295,258.727,57.459z M117.877,167.517H91.385l-5.892-22.652h32.384V167.517z M117.877,129.864H81.592
                                                        l-5.895-22.667h42.18V129.864z M117.877,92.197H71.795l-5.891-22.651h51.973V92.197z M176.119,167.517h-43.242v-22.652h43.242
                                                        V167.517z M176.119,129.864h-43.242v-22.667h43.242V129.864z M176.119,92.197h-43.242V69.546h43.242V92.197z M217.609,167.517
                                                        h-26.49v-22.652h32.382L217.609,167.517z M227.403,129.864h-36.284v-22.667h42.18L227.403,129.864z M237.201,92.197h-46.081V69.546
                                                        h51.974L237.201,92.197z"/>
                                                    <path d="M105.482,188.62c-15.106,0-27.396,12.29-27.396,27.395c0,15.108,12.29,27.4,27.396,27.4
                                                        c15.105,0,27.395-12.292,27.395-27.4C132.877,200.91,120.588,188.62,105.482,188.62z M105.482,228.415
                                                        c-6.835,0-12.396-5.563-12.396-12.4c0-6.835,5.561-12.395,12.396-12.395c6.834,0,12.395,5.561,12.395,12.395
                                                        C117.877,222.853,112.317,228.415,105.482,228.415z"/>
                                                    <path d="M203.512,188.62c-15.104,0-27.392,12.29-27.392,27.395c0,15.108,12.288,27.4,27.392,27.4
                                                        c15.107,0,27.396-12.292,27.396-27.4C230.908,200.91,218.618,188.62,203.512,188.62z M203.512,228.415
                                                        c-6.833,0-12.392-5.563-12.392-12.4c0-6.835,5.559-12.395,12.392-12.395c6.836,0,12.396,5.561,12.396,12.395
                                                        C215.908,222.853,210.347,228.415,203.512,228.415z"/>
                                                </g>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="pull-right">
                                        <button onClick={(e)=>addToBuy(e,data)} className="btnNine btnNine-default">Beli Sekarang</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
  )
}

export default ProductDetail