import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/authContext.js";
import { useNavigate,useLocation } from "react-router-dom";
import { FOLDER_MEDIA_PATH,thousandSeparator } from "../utils.js";
import { makeRequest } from "../axios.js";
import axios from "axios";
import '../sass/notmain.scss';
import { useLoading } from '../context/LoadingContext.js';

const Checkout = ({setLoginClicked}) => {
    const {currentUser} = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    let orderId = location.pathname.split('/')[2];
    const { showLoader, hideLoader } = useLoading();
    const [isOpen,setIsOpen] = useState(false);
    const [popupType,setPopupType] = useState('address');
    const [data,setData] = useState(null);
    const [addresses,setAddresses] = useState([]);
    const [shipments,setShipments] = useState([]);
    const [shipmentId,setShipmentId] = useState(0);

    const initData = async() => {
        try {
            const result = await makeRequest.get('orders/find-order?orderId='+orderId);
            if(result.data.data.status==='CANCELED') {
                alert('this order ID has been expired!')
                navigate('/');
            } else if(result.data.data.status!=='UNPAID') {
                navigate('/status-pesanan?orderId='+orderId);
            }
            setData(result.data.data);
            setShipmentId(result.data.data?.order_shipment?.shipment?.id);
        } catch (error) {
            alert('Order ID is not valid!')
            navigate('/');
        }
    }
    const initShipment = async() => {
        try {
            const result = await makeRequest.get('shipments/find-all');
            setShipments(result.data.data);
        } catch (error) {
            setShipments([]);
        }
    }
    const initAddress = async() => {
        try {
            const result = await makeRequest.get('user-address/find-all');
            setAddresses(result.data.data);
        } catch (error) {
            setAddresses([]);
        }
    }
    useEffect(()=>{
        if(!currentUser){
          setLoginClicked(true);
        } else {
            initData();
            initAddress();
            initShipment();
        }
    },[]);

    const pay = async(data) => {
        let dto = {
            orderId:data.id,
            amount:parseInt(data.total)+parseInt(data.order_shipment.price),
            shipment:{
                id:data.order_shipment.shipment.id,
                name:data.order_shipment.shipment.name,
                address:data.order_shipment.user_address.addressDetails,
                price:data.order_shipment.price,
            },
            items:data.order_items.map(item=>({
                id:item.productId,
                price:item.price,
                quantity:item.quantity,
                name:item.product.name,
                image:item.product.product_colors[0].product_color_images[0].file.name
            })),
            customer:{
                name:currentUser.name,
                email:currentUser.email
            }
        }
        dto.items.push({name:data.order_shipment.shipment.name,price:data.order_shipment.price,quantity:1});

        //update order_items
        await makeRequest.put('order-items?orderId='+data.id,data);

        //remove cart
        await makeRequest.put('carts/soft-delete');

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/transactions/create-transaction`,dto,{
                headers:{
                    'Content-type':'application/json'
                }
            });
            console.log('response.status:',response);
            if(response.status==201){
                window.location.href = response.data.data.snapRedirectUrl;
            } else {
                alert('servis midtrans sedang mengalami gangguan, mohon dicoba lagi nanti!');
            }
        } catch (error) {
            alert(error)
        }
    }
    const changeAddress = async(order,address) => {
        await showLoader();
        try {
            const dto = {
                orderId: order.id,
                addressId: address.id
            };
            await makeRequest.put('transactions/change-address',dto);
            window.location.reload();
        } catch (error) {
            alert(error.response.message);
        }
        await hideLoader();
    }
    const recalculate = (index,type) => {
        let objData = {...data};
        let isRemoved = false;
        let latestPrice = objData.order_items[index].price;
        let currentQty = 0;
        let oldPrice = 0;
        if(type==='minus'){
            if(objData.order_items[index].quantity < 2){
                oldPrice = objData.order_items[index].product.price;
                makeRequest.delete('order-items?id='+objData.order_items[index].id);
                objData.order_items.splice(index,1);
                isRemoved = true;
            } else {
                currentQty = objData.order_items[index].quantity-1;
            }
        } else if(type==='plus'){
            currentQty = objData.order_items[index].quantity < objData.order_items[index].product.product_colors[0].stock ? objData.order_items[index].quantity+1 : objData.order_items[index].quantity;
        }

        if(!isRemoved){
            let currentPrice = objData.order_items[index].product.price*currentQty;
            let currentTotal = objData.total-latestPrice+currentPrice;

            //set new value to data
            objData.total=currentTotal;
            objData.order_items[index].price=currentPrice;
            objData.order_items[index].quantity=currentQty;

            setData(objData);
        } else {
            objData.total -= oldPrice;
            setData(objData);
        }
    }
    const plus = (index) => {
        alert(index);
    }
    const openPopup = (type) => {
        setPopupType(type);
        setIsOpen(true);
    }
    const changeCourier = async() => {
        const order = data;

        const dto = {
            orderId: order.id,
            shipmentId: shipmentId,
            addressId: order.order_shipment.user_address.id,
            price: order.order_shipment.price
        };
        await makeRequest.put('order-shipment',dto);
        initData();
        setIsOpen(false);
    }
  return (
    <>
        <div className="section-profile profile-wrapper">
            {data != null && currentUser &&
                <div className="container">
                    <div className="content-profile checkout-profile">
                        <div className="content-profile-list active">
                            <div className="text-center mb2">
                                <div className="bold-caption">Belum Dibayar</div>
                            </div>
                            <div className="inline-space-start">
                                <div className="column-product-cart mr1">
                                    <div className="bbgrey-checkout mb1">
                                        <div className="green semibold">Informasi Pengiriman</div>
                                    </div>
                                    <div className="bbgrey-checkout mb1">
                                        <div className="inline-space">
                                            <div>Jasa Pengiriman</div>
                                            {/* <button className="text-right" type="button" onClick={()=>openPopup('shipment')}>Ubah</button> */}
                                            <div className="text-right">Regular - {data?.order_shipment?.shipment?.name}</div>
                                        </div>
                                    </div>
                                    <div className="bbgrey-checkout mb2">
                                        <div className="green semibold">Alamat Pengiriman</div>
                                    </div>
                                    <div className="bbgrey-checkout">
                                        <div className="address-box-checkout">
                                            <div className="flex" style={{alignItems:"center"}}>
                                                <div className="address-name"><span>{data?.order_shipment?.user_address?.addressReceiverName}</span> ({data?.order_shipment?.user_address?.type})</div>
                                                {data?.order_shipment?.user_address?.isMain && <div className="address-main">Utama</div>}
                                            </div>
                                            <div className="address-phone">{data?.order_shipment?.user_address?.addressPhoneNumber}</div>
                                            <p className="address-desc">{data?.order_shipment?.user_address?.addressDetails}</p>
                                        </div>
                                    </div>
                                    <div className="bbgrey-checkout">
                                        <div className="button-address-wrapper">
                                            <button onClick={()=>openPopup('address')} type="button" className="btnNine-address-checkout">Pilih Alamat Lain</button>
                                        </div>
                                    </div>
                                    {data?.order_items?.map((item,index)=>{
                                        return (
                                            <div className="bbgrey-checkout" key={item.id}>
                                                <div className="product-display-checkout">
                                                    <div className="checkout-product-foto">
                                                        <img src={FOLDER_MEDIA_PATH+item.product.product_colors[0].product_color_images[0].file.name} alt="productImage" />
                                                    </div>
                                                    <div className="checkout-product-desc">
                                                        <div className="checkout-brand">{item.product.brand.name}</div>
                                                        <div className="checkout-name">{item.product.name}</div>
                                                        <div className="checkout-color">Warna : <span>{item.product.product_colors[0].color.name}</span></div>
                                                        <div className="checkout-weight">{item.product.weight}gr</div>
                                                        <div className="checkout-quantity">
                                                            <i className="fa fa-minus" onClick={()=>recalculate(index,'minus')}></i>
                                                            <div>{item.quantity}</div>
                                                            <i className="fa fa-plus" onClick={()=>recalculate(index,'plus')}></i>
                                                        </div>
                                                        <div className="checkout-price">Rp {thousandSeparator(item.price)},-</div>
                                                    </div>
                                                    {/* <div className="checkout-product-quantity" style={{width:'150px',height:'150px',position:'relative'}}>
                                                        <div className="quantity" style={{display:'flex',gap:'10px'}}>
                                                            <i style={{cursor:'pointer'}} onClick={()=>minus(index)}>-</i>
                                                            <div>{item.quantity}</div>
                                                            <i style={{cursor:'pointer'}}  onClick={()=>plus(index)}>+</i>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="inline-space">
                                        <div className="semibold">Subtotal</div>
                                        <div className="semibold text-right">Rp {thousandSeparator(data.total)},-</div>
                                    </div>
                                </div>
                                <div className="column-product-cart">
                                    <div className="product-summary-cart">
                                        <div className="product-cart-wrapper">
                                            <div className="semibold fz16 black mb1">Ringkasan Belanja</div>
                                            <div className="bbgrey w100">
                                                <div className="inline-space fz12 w100">
                                                    <div>Total Harga</div>
                                                    <div className="text-right">Rp. {thousandSeparator(data?.total)},-</div>
                                                </div>
                                                <div className="inline-space fz12 w100">
                                                    <div>Total Ongkos Kirim ({data?.order_shipment.shipment.name})</div>
                                                    <div className="text-right">Rp. {thousandSeparator(data?.order_shipment?.price)},-</div>
                                                </div>
                                            </div>
                                            <div className="bbgrey w100 mb1">
                                                <div className="inline-space fz14 semibold w100">
                                                    <div>Total Pembayaran</div>
                                                    <div className="text-right">Rp. {thousandSeparator(parseInt(data?.total)+parseInt(data?.order_shipment?.price))},-</div>
                                                </div>
                                            </div>
                                            <div className="clearfix w100">
                                                <div className="pull-right">
                                                    <button type="button" onClick={(e)=>pay(data)} className="btnNine btnNine-default">Bayar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div className={isOpen ? "overlay-wrapper open" : "overlay-wrapper"} style={{display: isOpen ? 'block' : 'none'}}>
            <div className={popupType==='address' ? "popup-wrapper popup-big popup-current" : "popup-wrapper popup-big"}>
                <div className="middle">
                    <div className="inline-space text-left mb1">
                        <div className="filter-title fz18 bold">Ganti Alamat</div>
                    </div>
                    {addresses?.map(address=>(
                        <div className={data?.order_shipment?.userAddressId === address.id ? "bordered-box address-list-choice active" : "bordered-box address-list-choice"} key={address.id}>
                            <div className="btn-choice-wrapper">
                                <i className="fa fa-check"></i>
                                <a role='button' onClick={(e)=>changeAddress(data,address)} className="btnNine btnNine-default js-pick-address">Pilih</a>
                            </div>
                            <div className="flex">
                                <div className="address-name"><span>{address.addressReceiverName} </span> ({address.type})</div>
                                {address.isMain && <div className="address-main">Utama</div>}
                            </div>
                            <div className="address-phone" style={{textAlign:'left'}}>{address.addressPhoneNumber}</div>
                            <p className="address-desc" style={{textAlign:'left'}}>{address.addressDetails}</p>
                        </div>
                    ))}
                    <div className="clearfix">
                        <div className="pull-right">
                            <a role='button' onClick={()=>setIsOpen(false)} className="btnNine btnNine-secondary js-close-popup">Tutup</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={popupType==='shipment' ? "popup-wrapper popup-big popup-current" : "popup-wrapper popup-big"}>
                <div className="middle">
                    <div className="inline-space text-left mb1">
                        <div className="filter-title fz18 bold">Ganti Metode Pengiriman</div>
                    </div>
                    <select onChange={(e)=>setShipmentId(e.target.value)} value={shipmentId} style={{padding:'10px 0',borderRadius:'5px'}}>
                        {shipments?.map(shipment=>(
                            <option key={shipment.id} value={shipment.id}>{shipment.name}</option>
                        ))}
                    </select>
                    <div className="clearfix" style={{marginTop:'20px'}}>
                        <div className="pull-right">
                            <a role='button' onClick={changeCourier} className="btnNine btnNine-default js-close-popup">pilih</a>
                            <a role='button' onClick={()=>setIsOpen(false)} className="btnNine btnNine-secondary js-close-popup">Tutup</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="snap-container" style={{width:'100%'}}></div>
    </>
  )
}

export default Checkout